import { useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import Slider from "react-slick";
import { Link } from "react-router-dom";

//GRAPHQL
const QUERY = gql`
	query HeroNewsArticles {
		newsCollection(limit: 3) {
			items {
				sys {
					id
				}
				title
				slug
				heroImage {
					url
					title
				}
			}
		}
	}
`;

const sliderSettings = {
	dots: true,
	arrows: false,
	fade: true,
	infinite: true,
	speed: 1000,
	autoplay: true,
	autoplaySpeed: 30000,
	slidesToShow: 1,
	slidesToScroll: 1,
	className: "heroSlider",
};

const NewsSlider = () => {
	const { data, errors, loading } = useQuery(QUERY);

	const [newsArticles, setNewsArticles] = useState([]);

	useEffect(() => {
		data && data.newsCollection.items.length && setNewsArticles(data.newsCollection.items);
	}, [data]);

	return (
		<Slider {...sliderSettings}>
			{newsArticles.length &&
				newsArticles.map((newsItem, idx) => {
					return (
						<div className="slideWrapper" key={`newsSlider-${idx}`}>
							<Link to={`/news/${newsItem.slug}`} state={{ newsArticleId: newsItem.sys.id }} className="slideWrapper-content">
								<img src={newsItem.heroImage.url} alt={newsItem.heroImage.title} />
								<div className="newsTitle-wrapper fontColour-cream">
									<span className="fontFamily-feature fontSize-small">LATEST NEWS</span>
									<span className="limitLines-1 fontSize-medium">{newsItem.title}</span>
								</div>
							</Link>
						</div>
					);
				})}
		</Slider>
	);
};

export default NewsSlider;
